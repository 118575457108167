@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide {
  from {
    left: -200px;
  }
  to {
    left: 0px;
  }
}
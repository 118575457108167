.navbar {
  margin: 0;
  padding: 1rem 0 .5rem 0;
  width: 17rem;
  height: 100vh;
  position: fixed;
  background-color: #0F212E;
}

.navbarAnimation {
  animation: slide ease 1.5s;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.nav-item {
  padding: 0 0 0 .51rem;
  width: 100%;
}

.nav-item:last-child {
  margin-top: auto;
}

.nav-link {
  display: flex;
  align-items: center;
  height: 3.5rem;
  width: auto;
  color: white !important;
}

.nav-item:hover:not(:first-child):not(:last-child) {
  cursor: pointer;
  transition: 0.7s;
  box-shadow: -6px 0px #00a5ee inset;
  background-color: black;
}

.nav-item:first-child {
  padding-bottom: 1rem;
}

.section-items {
  font-size: 14px;
}

/* @Navbar horizontal */

.navbarHor {
  height: 4rem;
  margin-left: 17rem;
  background-color: white;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  
}

.fadeInAnimation {
  animation: fadeIn ease-in-out 1.5s forwards;
  animation-delay: 1.3s;
}

.navbarHor-nav {
  list-style: none;
  padding: 0 5rem 0 5rem;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.navHor-item {
  height: auto;
  width: 100%;
}

.horIcon:not(:last-child) {
  margin-right: 1rem;
  color: #1A2947;
}

.navHor-item:last-child {
  justify-content: flex-end;
  text-align: right;
}

.logo {
  margin-bottom: 1rem;
  width: 70%;
  height: 5rem;
  background-color: white;
  border-radius: 0.5rem;
}

.circleIconImg {
  width: 4%;
  height: 50%;
}
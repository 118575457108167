/* @import url('https://fonts.googleapis.com/css2?family=Piazzolla:wght@100;300;400&display=swap'); */

:root {
  font-size: 12px;
  --bg-primary: 161616;
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  /* font-family: 'Piazzolla', serif; */
  /* font-family: 'Roboto', sans-serif; */
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar {
  width: 1rem;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

main {
  margin-left: 14rem;
  padding: 1.5rem 5rem 0 7.5rem;
  color: rgba(0, 0, 0, 1);
  background-color: #f4f6fa;
  height: -webkit-calc(100% - 65px);
  /* google, safari */
}

.primary-text {
  color: #007aff !important;
}

.mainContent {
  margin: 1.5rem 5rem 0 5rem;
}

.loginParentDiv {
  justify-content: center;
}

.loginChildDiv {
  background-color: white;
  border-radius: 12px;
  padding: 1rem 2rem 1rem 2rem;
}

.btn-outline-custom {
  color: #00A2E5;
  background-color: transparent;
  background-image: none;
  border-color: #00A2E5;
  width: 100% !important;
}

.btn-outline-custom:hover {
  transition: 0.5s;
  color: #fff;
  background-color: #00a5ee;
  border-color: #00a5ee;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.svg-inline--fa:hover {
  cursor: pointer;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.flex-item {
  padding: 0.5rem;
  border-radius: 5px;
  width: 100%;
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.06);
}

.shadow-item {
  border-left: 0.6rem solid rgba(10, 92, 162, 0.7);
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.06);
}

.flex-item:not(:last-child) {
  padding: 0.5rem;
  border-radius: 5px;
  width: 100%;
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.1);
  margin-right: 1rem;
}

.flex-item-cards {
  width: 24%;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: white;
  border: solid 2.5px rgba(0, 0, 0, 0.05);
  transition: transform .3s;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.06);
  border-left: 0.3rem solid rgba(10, 92, 162, 0.7);
}

.flex-item-cards-btn {
  width: 33%;
}

.flex-item-cards:hover {
  border: solid 2.5px;
  cursor: pointer;
  border-color: #00a5ee;
}

.flex-item-cards>p {
  text-align: center;
}

.boldText {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: .1rem;
}

.titulo-seccion-form {
  border-left: solid 5px #3b537a;
  padding-left: 5px;
  color: rgb(77, 77, 77);
}

.custom-h6 {
  padding: 0.5rem 0 0.7rem 0.5rem;
  letter-spacing: 0.03rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  margin: 0 !important;
}

.custom-titles {
  padding: 0 0 0.5rem 0;
  letter-spacing: 0.05rem;
  font-weight: 600;
}

.hoverEl {
  cursor: pointer;
}

#hoverUp {
  padding: 0.5rem;
  display: inline-block;
  background-color: var(--bg-primary);
  width: 2rem;
  height: 2rem;
  text-align: center;
  border-radius: 50px;
  position: fixed;
  bottom: 40px;
  right: 3rem;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  color: white;
}

#hoverUp:hover {
  cursor: pointer;
  background-color: #333;
}

#hoverUp.show {
  opacity: 1;
  visibility: visible;
}

.title-high {
  font-size: 2rem;
}

.hover-link {
  cursor: pointer;
}

.hover-icon {
  cursor: pointer;
}

.tblPrecios td, th {
  border: none !important;
  font-size: 1rem;
}

.tblPrecios tr td {
  padding-top: 0.4em;
}

.custom-p {
  padding-top: 0.8rem !important;
  font-size: 1.3rem;
  /* font-weight: 500; */
}

.custom-lbl {
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
}

.container-img {
  background: url('../Images/gas-pump.png') no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 200px;
  height: 200px;
}

.custom-form-control {
  width: 50% !important;
}

.gas-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}